import { FC } from "react";
import LoadingButton, {
	LoadingButtonProps,
} from "@mui/lab/LoadingButton";

export interface BazaarCtaProps
	extends LoadingButtonProps {
	submitButtonText?: string;
}

const BazaarCTA: FC<BazaarCtaProps> = ({
	submitButtonText = "Continuer",
	children,
	...props
}: BazaarCtaProps) => {
	return (
		<LoadingButton
			fullWidth
			size='large'
			variant='contained'
			color='black'
			sx={{
				...props.sx,
				minHeight: 44,
				height: "fit-content",
				textAlign: "center",
			}}
			{...props}>
			{!children
				? submitButtonText
				: children}
		</LoadingButton>
	);
};

export default BazaarCTA;
