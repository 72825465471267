// ==========local host===========================

let ASSETS_URL =
	(process.env.NEXT_PUBLIC_S3_URL ||
		"https://backstore-medusa-server.s3.eu-west-3.amazonaws.com") +
	"/noomidoo/assets";

// Defaults to standard port for Medusa server
let BACKEND_URL =
	process.env.NEXT_PUBLIC_BACKEND_URL ||
	"http://127.0.0.1:9000";
// Defaults to standard port for Next.js frontend
let FRONTEND_URL =
	// if window is undefined, it means we are on the server side
	typeof window !== "undefined"
		? window.location.origin
		: process.env
				.NEXT_PUBLIC_FRONTEND_URL ||
		  "http://127.0.0.1:3000";

let SITE_REGION = "fr";
let SITE_LOCALE = "fr";
let SITE_NAME = "Noomidoo";
let SITE_DESCRIPTION =
	SITE_NAME +
	" est un dépôt-vente et une friperie en ligne où vous pouvez acheter et vendre des vêtements de seconde main de haute qualité. Trouvez vos marques préférées avec jusqu'à 90 % de réduction.";
let SEARCH_PAGE_DESCRIPTION =
	"Ici vous trouverez tous les produits de notre boutique, incluant les produits pour Femme, Homme et Enfants.";
let IS_SITE_LIVE = false;
let SITE_NOINDEX_DEFAULT = true;
let SITE_NOFOLLOW_DEFAULT = true;
let DISALLOW_ROBOTS = true;
let SITE_SLOGAN =
	"Dépôt-vente et Friperie en ligne";
let CONTACT_PHONE = "+33 9 70 70 73 90";
let DIRECTION_CONTACT_EMAIL =
	"contact@noomidoo.com";

let CLIENT_SERVICE_CONTACT_EMAIL =
	"service.client@noomidoo.com";

let CLIENT_SERVICE_CONTACT_EMAIL_WITH_NAME =
	"Support" +
	SITE_NAME +
	" <" +
	CLIENT_SERVICE_CONTACT_EMAIL +
	">";

let NOREPLY_EMAIL =
	"Noomidoo <no-reply@noomidoo.com>";

let CONTACT_ADDRESS =
	"2b rue Dupont de l'Eure, 75020 Paris, France";

let HELP_CENTER_URL = "";

let ABOUT_PAGE_URL = `/manifesto`;

let LEGAL_PAGE_URL = `/legal`;
let LEGAL_NOTICE_PAGE_URL = `${LEGAL_PAGE_URL}/mentions-legales`;
let LEGAL_CGU_PAGE_URL = `${LEGAL_PAGE_URL}/conditions-generales`;
let LEGAL_PRIVACY_POLICY_PAGE_URL = `${LEGAL_PAGE_URL}/politique-de-confidentialite`;
let LEGAL_COOKIES_NOTICE_PAGE_URL = `${LEGAL_PAGE_URL}/avis-sur-cookies`;

let FAQ_PAGE_URL = `/foire-aux-questions`;
let FAQ_RETURNS_AND_FUNDS_PAGE_URL = `${FAQ_PAGE_URL}/retours-et-remboursements`;

let FAQ_DELIVERY_SHIPPING_PAGE_URL = `${FAQ_PAGE_URL}/expedition-et-livraison`;
let FAQ_ORDERS_PAGE_URL = `${FAQ_PAGE_URL}/commandes`;
let FAQ_CANCEL_ORDER_OR_ITEM_PAGE_URL = `${FAQ_ORDERS_PAGE_URL}/annuler-une-commande`;
let FAQ_PAYMENT_PAGE_URL = `${FAQ_PAGE_URL}/paiement`;
let FAQ_PAYMENT_GET_INVOICE_PAGE_URL = `${FAQ_PAYMENT_PAGE_URL}/comment-obtenir-une-facture`;

let SIZE_GUIDE_PAGE_URL = `/guide-des-tailles`;

let FAQ_TRY_AND_BUY_LATER_PAGE_URL = `${FAQ_PAYMENT_PAGE_URL}/comment-payer-ma-commande-avec-klarna`;

let FAQ_SHIPPING_FEE_PAGE_URL = `${FAQ_DELIVERY_SHIPPING_PAGE_URL}/frais-de-livraison`;

let FAQ_HOW_TO_PLACE_AN_ORDER_PAGE_URL = `${FAQ_ORDERS_PAGE_URL}/passer-une-commande`;

// social media
let FACEBOOK_USERNAME = "";
let FACEBOOK_APP_ID = "";
let INSTAGRAM_USERNAME = "";
let X_TWITTER_USERNAME = "";
let PINTEREST_USERNAME = "";
let YOUTUBE_USERNAME = "";
let LINKEDIN_USERNAME = "";
let SNAPCHAT_USERNAME = "";
let TikTok_USERNAME = "";
//================================================

if (
	process.env.NEXT_PUBLIC_SITE_REGION
) {
	SITE_REGION =
		process.env.NEXT_PUBLIC_SITE_REGION;
}

if (
	process.env.NEXT_PUBLIC_SITE_LOCALE
) {
	SITE_LOCALE =
		process.env.NEXT_PUBLIC_SITE_LOCALE;
}

if (process.env.NEXT_PUBLIC_SITE_NAME) {
	SITE_NAME =
		process.env.NEXT_PUBLIC_SITE_NAME;
}

if (
	process.env
		.NEXT_PUBLIC_SITE_DESCRIPTION
) {
	SITE_DESCRIPTION =
		process.env
			.NEXT_PUBLIC_SITE_DESCRIPTION;
}

if (
	process.env
		.NEXT_PUBLIC_SEARCH_PAGE_DESCRIPTION
) {
	SEARCH_PAGE_DESCRIPTION =
		process.env
			.NEXT_PUBLIC_SEARCH_PAGE_DESCRIPTION;
}

if (
	process.env.NEXT_PUBLIC_IS_SITE_LIVE
) {
	IS_SITE_LIVE =
		process.env
			.NEXT_PUBLIC_IS_SITE_LIVE ===
		"true"
			? true
			: false;
}

if (
	process.env
		.NEXT_PUBLIC_SITE_NOINDEX_DEFAULT
) {
	SITE_NOINDEX_DEFAULT =
		process.env
			.NEXT_PUBLIC_SITE_NOINDEX_DEFAULT ===
		"true"
			? true
			: false;
}

if (
	process.env
		.NEXT_PUBLIC_SITE_NOFOLLOW_DEFAULT
) {
	SITE_NOFOLLOW_DEFAULT =
		process.env
			.NEXT_PUBLIC_SITE_NOFOLLOW_DEFAULT ===
		"true"
			? true
			: false;
}

if (
	process.env
		.NEXT_PUBLIC_DISALLOW_ROBOTS
) {
	DISALLOW_ROBOTS =
		process.env
			.NEXT_PUBLIC_DISALLOW_ROBOTS ===
		"true"
			? true
			: false;
}

if (
	process.env.NEXT_PUBLIC_SITE_SLOGAN
) {
	SITE_SLOGAN =
		process.env.NEXT_PUBLIC_SITE_SLOGAN;
}

if (
	process.env.NEXT_PUBLIC_CONTACT_PHONE
) {
	CONTACT_PHONE =
		process.env
			.NEXT_PUBLIC_CONTACT_PHONE;
}

if (
	process.env
		.NEXT_PUBLIC_CLIENT_SERVICE_CONTACT_EMAIL
) {
	CLIENT_SERVICE_CONTACT_EMAIL =
		process.env
			.NEXT_PUBLIC_CLIENT_SERVICE_CONTACT_EMAIL;
}

if (
	process.env
		.NEXT_PUBLIC_DIRECTION_CONTACT_EMAIL
) {
	DIRECTION_CONTACT_EMAIL =
		process.env
			.NEXT_PUBLIC_DIRECTION_CONTACT_EMAIL;
}

if (
	process.env.NEXT_PUBLIC_NOREPLY_EMAIL
) {
	NOREPLY_EMAIL =
		process.env
			.NEXT_PUBLIC_NOREPLY_EMAIL;
}

if (
	process.env
		.NEXT_PUBLIC_CONTACT_ADDRESS
) {
	CONTACT_ADDRESS =
		process.env
			.NEXT_PUBLIC_CONTACT_ADDRESS;
}

if (
	process.env
		.NEXT_PUBLIC_FACEBOOK_USERNAME
) {
	FACEBOOK_USERNAME =
		process.env
			.NEXT_PUBLIC_FACEBOOK_USERNAME;
}

if (
	process.env
		.NEXT_PUBLIC_FACEBOOK_APP_ID
) {
	FACEBOOK_APP_ID =
		process.env
			.NEXT_PUBLIC_FACEBOOK_APP_ID;
}

if (
	process.env
		.NEXT_PUBLIC_INSTAGRAM_USERNAME
) {
	INSTAGRAM_USERNAME =
		process.env
			.NEXT_PUBLIC_INSTAGRAM_USERNAME;
}

if (
	process.env
		.NEXT_PUBLIC_TWITTER_USERNAME
) {
	X_TWITTER_USERNAME =
		process.env
			.NEXT_PUBLIC_TWITTER_USERNAME;
}

if (
	process.env
		.NEXT_PUBLIC_PINTEREST_USERNAME
) {
	PINTEREST_USERNAME =
		process.env
			.NEXT_PUBLIC_PINTEREST_USERNAME;
}

if (
	process.env
		.NEXT_PUBLIC_YOUTUBE_USERNAME
) {
	YOUTUBE_USERNAME =
		process.env
			.NEXT_PUBLIC_YOUTUBE_USERNAME;
}

if (
	process.env
		.NEXT_PUBLIC_LINKEDIN_USERNAME
) {
	LINKEDIN_USERNAME =
		process.env
			.NEXT_PUBLIC_LINKEDIN_USERNAME;
}

if (
	process.env
		.NEXT_PUBLIC_SNAPCHAT_USERNAME
) {
	SNAPCHAT_USERNAME =
		process.env
			.NEXT_PUBLIC_SNAPCHAT_USERNAME;
}

if (
	process.env
		.NEXT_PUBLIC_TikTok_USERNAME
) {
	TikTok_USERNAME =
		process.env
			.NEXT_PUBLIC_TikTok_USERNAME;
}
const HITS_PER_PAGE = 36;
// Routes
const PRODUCTS_FEED_ROOT =
	"/products-feed";
const MANIFEST_ROOT = "/manifest";
const BLOG_NAME = "blog";
const BLOG_ROOT = `https://${BLOG_NAME}.noomidoo.com`;
const CART_ROOT = "/panier";
const CHECKOUT_ROOT = "/checkout";
const CHECKOUT_TUNNEL_ROOT = `${CHECKOUT_ROOT}/tunnel`;
const CHECKOUT_REDIRECT_ROOT = `${CHECKOUT_ROOT}/redirect`;
const CHECKOUT_FAIL_ROOT = `${CHECKOUT_ROOT}/fail`;
const ORDER_ROOT = "/commande";
const ORDER_CONFIRMED_ROOT = `${ORDER_ROOT}/confirmation`;
const ORDER_DETAILS_ROOT = `${ORDER_ROOT}/details`;

const ACCOUNT_ROOT = "/compte";
const ACCOUNT_OVERVIEW_ROOT = `${ACCOUNT_ROOT}/apercu`;
const ACCOUNT_ORDERS_ROOT = `${ACCOUNT_ROOT}/commandes`;
const ACCOUNT_ORDERS_DETAILS_ROOT = `${ACCOUNT_ORDERS_ROOT}/details`;

const ACCOUNT_PROFILE_ROOT = `${ACCOUNT_ROOT}/profil`;
const ACCOUNT_ADDRESSES_ROOT = `${ACCOUNT_ROOT}/addresses`;
const ACCOUNT_WISHLIST_ROOT = `${ACCOUNT_ROOT}/liste-de-souhaits`;
const PRODUCT_ROOT = "/produit";
const SECTION_NAME = "section";
const SECTION_ROOT = `/${SECTION_NAME}`;
const ALL_SECTIONS_ROOT = `${SECTION_ROOT}/tout`;
const BRAND_NAME = "marque";
const SEASON_NAME = "saison";
const BRAND_ROOT = `/${BRAND_NAME}`;
const SEASON_ROOT = `/${SEASON_NAME}`;
const CATALOG_NAME = "catalogue";
const CATALOG_ROOT = `/${CATALOG_NAME}`;
const AUTH_ROOT = "/auth";
const RESET_PASSWORD_ROOT =
	"/reinitialiser-mot-passe";

const CLEANOUT_ROOT = "/vide-dressings";
const CLEANOUT_SELL_ROOT = `${CLEANOUT_ROOT}/vendre`;
const CLEANOUT_CONSOLE_ROOT = `${CLEANOUT_ROOT}/console`;
const CLEANOUT_CONSOLE_CASHOUT_ROOT = `${CLEANOUT_CONSOLE_ROOT}/retrait-argent`;
const ACCOUNT_CLEANOUT_CASHOUT_STRIPE_ONBOARDING_ROOT = `${CLEANOUT_CONSOLE_CASHOUT_ROOT}/stripe/onboarding`;
const ACCOUNT_CLEANOUT_CASHOUT_STRIPE_CREATE_ACCOUNT_ROOT = `${ACCOUNT_CLEANOUT_CASHOUT_STRIPE_ONBOARDING_ROOT}/create`;
const ACCOUNT_CLEANOUT_CASHOUT_STRIPE_RETURN_ROOT = `${ACCOUNT_CLEANOUT_CASHOUT_STRIPE_ONBOARDING_ROOT}/return`;
const ACCOUNT_CLEANOUT_CASHOUT_STRIPE_REFRESH_ROOT = `${ACCOUNT_CLEANOUT_CASHOUT_STRIPE_ONBOARDING_ROOT}/refresh`;

const CONTACT_ROOT = "/contact";

const PRIVACY_SETTINGS_ROOT = `/parametres-confidentialite`;

const config = {
	// Config
	ASSETS_URL,
	BACKEND_URL,
	FRONTEND_URL,
	SITE_REGION,
	SITE_LOCALE,
	SITE_NAME,
	SITE_SLOGAN,
	SITE_DESCRIPTION,
	SEARCH_PAGE_DESCRIPTION,
	IS_SITE_LIVE,
	SITE_NOINDEX_DEFAULT,
	SITE_NOFOLLOW_DEFAULT,
	DISALLOW_ROBOTS,
	CONTACT_PHONE,
	DIRECTION_CONTACT_EMAIL,
	CLIENT_SERVICE_CONTACT_EMAIL,
	CLIENT_SERVICE_CONTACT_EMAIL_WITH_NAME,
	NOREPLY_EMAIL,
	CONTACT_ADDRESS,
	// constants
	HITS_PER_PAGE,
	//  product feeds
	PRODUCTS_FEED_ROOT,
	// manifest
	MANIFEST_ROOT,
	// Blog
	BLOG_NAME,
	BLOG_ROOT,
	// help center
	HELP_CENTER_URL,
	ABOUT_PAGE_URL,
	LEGAL_PAGE_URL,
	LEGAL_NOTICE_PAGE_URL,
	LEGAL_CGU_PAGE_URL,
	LEGAL_PRIVACY_POLICY_PAGE_URL,
	LEGAL_COOKIES_NOTICE_PAGE_URL,
	FAQ_PAGE_URL,
	FAQ_RETURNS_AND_FUNDS_PAGE_URL,
	FAQ_CANCEL_ORDER_OR_ITEM_PAGE_URL,
	FAQ_DELIVERY_SHIPPING_PAGE_URL,
	FAQ_SHIPPING_FEE_PAGE_URL,
	FAQ_ORDERS_PAGE_URL,
	FAQ_HOW_TO_PLACE_AN_ORDER_PAGE_URL,
	FAQ_PAYMENT_PAGE_URL,
	FAQ_PAYMENT_GET_INVOICE_PAGE_URL,
	FAQ_TRY_AND_BUY_LATER_PAGE_URL,
	SIZE_GUIDE_PAGE_URL,

	// social media
	FACEBOOK_USERNAME,
	FACEBOOK_APP_ID,
	INSTAGRAM_USERNAME,
	X_TWITTER_USERNAME,
	PINTEREST_USERNAME,
	YOUTUBE_USERNAME,
	LINKEDIN_USERNAME,
	SNAPCHAT_USERNAME,
	TikTok_USERNAME,
	// Routes
	CART_ROOT,
	CHECKOUT_ROOT,
	CHECKOUT_TUNNEL_ROOT,
	CHECKOUT_REDIRECT_ROOT,
	CHECKOUT_FAIL_ROOT,
	ORDER_ROOT,
	ORDER_CONFIRMED_ROOT,
	ORDER_DETAILS_ROOT,
	ACCOUNT_ROOT,
	ACCOUNT_OVERVIEW_ROOT,
	ACCOUNT_ORDERS_ROOT,
	ACCOUNT_ORDERS_DETAILS_ROOT,
	ACCOUNT_PROFILE_ROOT,
	ACCOUNT_ADDRESSES_ROOT,
	ACCOUNT_WISHLIST_ROOT,
	PRODUCT_ROOT,
	SECTION_NAME,
	SECTION_ROOT,
	ALL_SECTIONS_ROOT,
	BRAND_NAME,
	SEASON_NAME,
	CATALOG_NAME,
	CATALOG_ROOT,
	BRAND_ROOT,
	SEASON_ROOT,
	AUTH_ROOT,
	RESET_PASSWORD_ROOT,
	CLEANOUT_ROOT,
	CLEANOUT_SELL_ROOT,
	CLEANOUT_CONSOLE_ROOT,
	CLEANOUT_CONSOLE_CASHOUT_ROOT,
	ACCOUNT_CLEANOUT_CASHOUT_STRIPE_ONBOARDING_ROOT,
	ACCOUNT_CLEANOUT_CASHOUT_STRIPE_CREATE_ACCOUNT_ROOT,
	ACCOUNT_CLEANOUT_CASHOUT_STRIPE_RETURN_ROOT,
	ACCOUNT_CLEANOUT_CASHOUT_STRIPE_REFRESH_ROOT,
	CONTACT_ROOT,
	PRIVACY_SETTINGS_ROOT,
};

module.exports = config;
